var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"navbar"}},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#app'),expression:"'#app'"}],attrs:{"to":'/' + _vm.$i18n.locale + '/aboutme'}},[_c('img',{attrs:{"src":require("../assets/logo_dark.png"),"height":"27","alt":"Logo"}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"text-left"},[_c('b-nav-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                    el: '#main-view',
                    offset: _vm.$breakpoint.smAndDown ? -270 : -76
                }),expression:"{\n                    el: '#main-view',\n                    offset: $breakpoint.smAndDown ? -270 : -76\n                }"}],attrs:{"to":'/' + _vm.$i18n.locale + '/aboutme#main-view'}},[_vm._v(" "+_vm._s(_vm.$t('aboutme'))+" ")]),_c('b-nav-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                    el: '#main-view',
                    offset: _vm.$breakpoint.smAndDown ? -270 : -76
                }),expression:"{\n                    el: '#main-view',\n                    offset: $breakpoint.smAndDown ? -270 : -76\n                }"}],attrs:{"to":'/' + _vm.$i18n.locale + '/services#main-view'}},[_vm._v(" "+_vm._s(_vm.$t('services'))+" ")]),_c('b-nav-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                    el: '#main-view',
                    offset: _vm.$breakpoint.smAndDown ? -270 : -76
                }),expression:"{\n                    el: '#main-view',\n                    offset: $breakpoint.smAndDown ? -270 : -76\n                }"}],attrs:{"to":'/' + _vm.$i18n.locale + '/blog#main-view'}},[_vm._v(" "+_vm._s(_vm.$t('blog'))+" ")]),_c('b-nav-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                    el: '#main-view',
                    offset: _vm.$breakpoint.smAndDown ? -270 : -76
                }),expression:"{\n                    el: '#main-view',\n                    offset: $breakpoint.smAndDown ? -270 : -76\n                }"}],attrs:{"to":'/' + _vm.$i18n.locale + '/contact#main-view'}},[_vm._v(" "+_vm._s(_vm.$t('contact'))+" ")])],1),_c('b-navbar-nav',{staticClass:"ml-auto text-left"},[_c('b-nav-item-dropdown',{attrs:{"text":_vm.$i18n.locale,"right":""}},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.changeLocale('en')}}},[_vm._v("en")]),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.changeLocale('de')}}},[_vm._v("de")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }